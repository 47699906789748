import type { PhoneCode } from '#types/config/phoneCode'

/**
 * @param countryCode - country code
 * @returns phonecode
 */
export const getPhoneCodeByCountryCode = (countryCode: string) => {
  const phoneCodes = useRuntimeConfig().public.phoneCodes as PhoneCode[]
  return phoneCodes.find(({ code }) => code === countryCode.toUpperCase())?.dialCode
}
